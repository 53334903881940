import React, { useState } from 'react'
import {
    ModalHeader,
    ModalContent,
    ModalActions,
    Button,
    Image,
    Modal,
    Grid,
    GridRow,
    GridColumn,
    Input,
    Icon,
    CardGroup,
    Card,
    CardContent,
    CardHeader,
    CardMeta,
} from 'semantic-ui-react'
import { shortTitleLength } from '../../../utils/common';

const ModalAgentUsers = (props) => {
    const makeAgentUser = (e) => {
        props?.makeAgentUser({ childAgent: e?._id, parentAgent: props?.childAgentUser?.userInfo?._id || "" })
        props?.setChooseAgent((prev) => prev.includes(e?._id) ? prev.filter((i) => i !== e?._id) : [...prev, e?._id]);
    }
    return (
        <Modal
            onClose={() => props?.onClose(false)}
            onOpen={props?.onOpen}
            open={props?.onOpen}
        >
            <ModalHeader>
                <Grid columns={3} >
                    <GridRow>
                        <GridColumn style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                        }}>

                            {props?.childAgentUser?.userInfo?.name ?
                                <> <Image
                                    floated='left'
                                    circular={true}
                                    size='mini'
                                    src={props?.childAgentUser?.userInfo?.profilePic}
                                    style={{ height: "50px", width: "50px", objectFit: "cover" }} />
                                    {props?.childAgentUser?.userInfo?.name}
                                </>
                                : <>
                                    <Icon name='users' size='large' /> Make Parent Agent
                                </>
                            }
                        </GridColumn>
                        <GridColumn> </GridColumn>
                        <GridColumn>
                            <Input type='text'
                                size='mini'
                                onKeyPress={(e) => { if (e.key === "Enter") return props?.searchOnClick(); }}
                                value={props?.value} placeholder='Search...' action onChange={(e) => props?.onSearch(e)}>
                                <input />
                                <Button type='submit' onClick={() => { props?.searchOnClick() }} icon><Icon name="search" fitted /></Button>
                            </Input>
                        </GridColumn>
                    </GridRow>
                </Grid>
            </ModalHeader>
            <ModalContent style={{ minHeight: "520px" }}>
                <CardGroup itemsPerRow={4} >
                    {(props?.agentData || []).map((e, i) => (
                        <Card key={e?._id} style={{
                            cursor: "pointer",
                            background: e?.isAgent > 0 ? "#09680930" : props?.chooseAgent.includes(e?._id) ? "#09680930" : null,
                            transition: "background-color 0.5s ease-in-out",

                        }}
                            onClick={() => { makeAgentUser(e) }}>
                            <CardContent>
                                <Image
                                    circular={true}
                                    floated='right'
                                    size='mini'
                                    src={e?.image}
                                />
                                <CardHeader>{e?.name}</CardHeader>
                                <CardMeta>{e?.gender}</CardMeta>
                                <CardMeta>{e?.mobile}</CardMeta>
                                <CardMeta>{shortTitleLength(e?.email, 25)}</CardMeta>
                                <Button
                                    size='mini'
                                    color={e?.status === 0 ? "orange" : e?.status === 1 ? "green" : e?.status === 2 ? "red" : null
                                    }>{e?.status === 0 ? "New" : e?.status === 1 ? "Active" : e?.status === 2 ? "Inactive" : null}
                                </Button>

                            </CardContent>
                        </Card>
                    ))}
                </CardGroup>
            </ModalContent>
            <ModalActions>
                <Button onClick={() => props?.onClose(false)}>Cancel</Button>
            </ModalActions>
        </Modal>
    )
}

export default ModalAgentUsers