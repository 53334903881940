import React, { useState, useEffect } from "react";
import momentTz from "moment-timezone";
import { CSVLink } from "react-csv";
import {
    Button, Icon, Label, Dropdown, Input, Pagination,
    Card, CardHeader, CardGroup, CardMeta, CardContent, CardDescription,
    Feed, FeedEvent, FeedLabel, FeedContent, FeedSummary, FeedDate,
    Modal, ModalHeader, ModalContent, ModalActions,
    Grid, GridColumn, GridRow,
    Loader,
    Divider,
    LabelDetail,
    Image,
    List,
    ListItem,
} from "semantic-ui-react";
import { POST } from "../../../Services";

import "./style.css";
import { dateTimeFormat, insightsCSV, NoData, shortTitleLength } from "../../../utils/common";
import { notifyToast } from "../../../utils/toast";
import CSVDownload from "../../component/CSVDownload/CSVDownload";
let filterCount = 0;

let pageRequest = {
    pageNumber: 0,
    pageLimit: 25,
    filterValue: { startDate: '', endDate: '', },
    search: "",
    sort: {},
    csvFile: false
};

const defaultStateObj = {
    isLoading: false,
    userData: [],
    totalPages: 10,
    totalRecords: 0,
    loader: undefined,
    openUserImageModel: false,
    openViewModel: false,
    openQRImgModel: false,
    userDetails: {},
    filterModal: false,
    sortingUser: false,
    manualSerach: "",
    pagiActivePage: 1,
    copyReferralCode: "",
    userCSVData: [],
    csvLoader: false
};

const Insights = () => {
    const [userData, setUserData] = useState([]);
    const [state, setState] = useState(defaultStateObj);
    const [sortingUser, setSortingUser] = useState(false);
    const [manualSerach, setManualSearch] = useState("");
    const [isCsvFile, setIsCsvFile] = useState(false);
    const [CSVData, setCSVData] = useState([])

    const sortUsers = (type, data) => {
        try {
            if (data === "user") {
                if (type === 0) {
                    pageRequest.sort = { field: "createdAt", order: 1 }
                    fetchData();
                    setSortingUser(true);
                } else {
                    pageRequest.sort = { field: "createdAt", order: -1 }
                    fetchData();
                    setSortingUser(false);
                }
            }
        } catch (er) { console.log(er); }
    };

    const applyFilter = () => {
        if (
            (!pageRequest.filterValue.startDate) ||
            (!pageRequest.filterValue.endDate) ||
            (pageRequest.filterValue.startDate && !pageRequest.filterValue.endDate) ||
            (pageRequest.filterValue.endDate && !pageRequest.filterValue.startDate)
        ) {
            return notifyToast("Please select date", 'error')
        };
        filterCount = 1
        setState((p) => ({ ...p, filterModal: false }))
        fetchData()
    };

    const clearFilters = () => {
        filterCount = 0;
        pageRequest = {
            pageNumber: 0,
            pageLimit: 25,
            filterValue: { startDate: '', endDate: '', },
            search: "",
            sort: {},
        }
        fetchData();
    };

    const searchOnClick = (e) => {
        pageRequest.pageNumber = 0
        pageRequest.search = manualSerach
        fetchData();
        if (manualSerach === "") {
            pageRequest.search = ""
            fetchData()
        }
    };

    const pageChange = async (item, data) => {
        let pageNum = data.activePage - 1;
        pageRequest.pageNumber = pageNum;
        setState((pre) => ({ ...pre, pagiActivePage: data.activePage }))
        fetchData();
    };

    const changeRange = (e, data) => {
        setTimeout(() => {
            setState((pre) => ({ ...pre, pagiActivePage: 1, totalPages: 1 }));
            pageRequest.pageNumber = 0
            pageRequest.pageLimit = data.value;
            fetchData();
        }, 0);
    };

    const onSearch = (e) => {
        let value = e.target.value
        setManualSearch(value)
        if (value.length === 0) {
            pageRequest.search = ""
            fetchData();
        }
    };

    const dropPagiOptions = [
        { key: 1, text: 25, value: 25, }, { key: 2, text: 50, value: 50, },
        { key: 3, text: 75, value: 75, }, { key: 4, text: 100, value: 100, },
        { key: 5, text: 125, value: 125, }, { key: 6, text: 150, value: 150, }
    ];

    const fetchData = async () => {
        const { status, totalRecords = 0, totalPages = 0, payload } = await POST("/v1/user/get-insights", { ...pageRequest });
        // console.log("payload===================>>", payload)
        insightsCSV(payload)
        setState((pre) => ({ ...pre, loader: status, totalPages, totalRecords }));
        setUserData(payload);
    };

    useEffect(() => {
        pageRequest.search = "";
        fetchData()
    }, []);

    const generateCSV = async () => {
        pageRequest.csvFile = true;
        setIsCsvFile(true)
        const { payload } = await POST("/v1/user/get-insights", { ...pageRequest });
        setCSVData(insightsCSV(payload))
        pageRequest.csvFile = false;
        setIsCsvFile(false)
    }

    return (
        <>
            <section className="fixed-position" style={{ minHeight: "90px" }}>
                <div className="fixed-position-inner">
                    <Grid stackable>
                        <Grid.Row className="custom-userlist">
                            <Grid.Column width={2} className="custom-userlist-1">
                                <Button.Group>
                                    <Button icon labelPosition='left' onClick={() => { setState((p) => ({ ...p, filterModal: true })) }}>
                                        <Icon name='filter' />Filters
                                        {filterCount === 0 ? null : <Label color="teal" size="mini" circular floating horizontal style={{ left: "120%", borderRadius: "15px" }}>
                                            {filterCount}
                                        </Label>}
                                    </Button>
                                    {filterCount === 0 ? null : <Button color="red" icon onClick={() => clearFilters()}>
                                        <Icon name="close" />
                                    </Button>}
                                </Button.Group>
                            </Grid.Column>

                            <Grid.Column width={1} className="custom-userlist-2">
                                {sortingUser === false ? (
                                    <Icon
                                        name="sort amount up"
                                        titile="Latest Users"
                                        className="icon-font-size"
                                        onClick={() => sortUsers(0, "user")}
                                    />
                                ) : (
                                    <Icon
                                        name="sort amount down"
                                        titile="Oldest Users"
                                        className="icon-font-size"
                                        onClick={() => sortUsers(1, "user")}
                                    />
                                )}
                            </Grid.Column>

                            <Grid.Column width={3} className="custom-userlist-3" >
                                <Input type='text' onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        return searchOnClick();
                                    }
                                }}
                                    value={manualSerach} placeholder='Search...' action onChange={onSearch}>
                                    <input />
                                    <Button type='submit' onClick={searchOnClick} icon><Icon name="search" fitted /></Button>
                                </Input>
                            </Grid.Column>

                            <Grid.Column width={4}>
                                <Pagination
                                    style={{ marginLeft: "40px" }}
                                    boundaryRange={1}
                                    activePage={state.pagiActivePage}
                                    siblingRange={0}
                                    firstItem={null}
                                    lastItem={null}
                                    pointing
                                    secondary
                                    totalPages={state.totalPages}
                                    onPageChange={(event, data) => pageChange(event, data)}
                                />
                            </Grid.Column>

                            <Grid.Column width={1} style={{ paddingTop: 12 }} className="custom-userlist-5">
                                <Dropdown
                                    placeholder="25"
                                    options={dropPagiOptions}
                                    onChange={(e, data) => changeRange(e, data)}
                                />
                            </Grid.Column>

                            <Grid.Column width={2} style={{ paddingTop: 13 }} className="custom-userlist-5">
                                <Label as='a' color="green" >
                                    <LabelDetail>Total Users {state?.totalRecords || 0}</LabelDetail>
                                </Label>
                            </Grid.Column>

                            <Grid.Column width={3} style={{ paddingTop: 13 }}>
                                <CSVDownload
                                    generateCSV={(e) => generateCSV()}
                                    csvLoader={isCsvFile}
                                    CSVData={CSVData}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </section>
            <CardGroup itemsPerRow={4}>
                {userData.length ?
                    <> {(userData || []).map((ele, index) => (
                        <Card key={ele._id}>
                            <CardContent>
                                <Label style={{ paddingLeft: "15px" }} className="circular-label" circular>{index + 1}</Label>
                                <Image
                                    floated='right'
                                    circular={true}
                                    size='mini'
                                    src={ele?.profilePic}
                                    style={{ height: "50px", width: "50px", objectFit: "cover" }}
                                />
                                <CardHeader >{ele?.name}<strong style={{ color: "#d1d1d1" }}> | </strong> {ele?.gender}<strong style={{ color: "#d1d1d1" }}> | </strong> {ele?.age} </CardHeader>
                                <CardHeader> {ele.mobile}</CardHeader>
                                <CardMeta >{ele?.email ? shortTitleLength(ele?.email, 25) : <span style={{ color: "#b7b6b62b" }}>___________________</span>}  </CardMeta>
                                <CardMeta>{ele?.address}</CardMeta>
                                {/* <CardMeta>{dateTimeFormat(ele?.createdAt)}</CardMeta> */}
                                <Grid style={{ marginTop: "2px" }}>
                                    <Grid.Row >
                                        <Grid.Column width={6} className="box-center" >
                                            <List>
                                                <ListItem>
                                                    <Image
                                                        src={ele.qrImage}
                                                        style={{ height: "80px", width: "80px", objectFit: "cover" }}
                                                    />
                                                </ListItem>
                                                <ListItem style={{ textAlign: "center" }}>   <CardHeader> {ele.referralCode}</CardHeader></ListItem>
                                            </List>
                                        </Grid.Column>
                                        <Grid.Column width={10}  >
                                            <List>
                                                <ListItem><div> <Icon name="qrcode" titile="qrcode user" /> Scan Hit <Label >{ele.scanHitCount}</Label></div> </ListItem>
                                                <ListItem> <div><Icon name="download" titile="download user" /> App Download  <Label >{ele.appDownloadCount}</Label></div></ListItem>
                                                <ListItem> <div><Icon name="users" titile="qrcode user" /> Sign Up  <Label >{ele.signUpCompleteUserCount}</Label></div></ListItem>
                                                <ListItem><div> <Icon name="certificate" titile="qrcode user" /> Verifyed Profile <Label >{ele?.verifyUserCount}</Label></div></ListItem>
                                            </List>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                {/* <Grid columns={2} divided style={{ marginTop: "2px" }} >
                                    <GridRow >
                                        <GridColumn className="box-center">
                                            <Image
                                                // size='massive'
                                                src={ele.qrImage}
                                                style={{ height: "80px", width: "80px", objectFit: "cover" }}
                                            />
                                        </GridColumn>

                                        <GridColumn  >
                                            <List>
                                                <ListItem>Scan Hit Count</ListItem>
                                                <ListItem>App Download Count</ListItem>
                                                <ListItem>Sign Up Count</ListItem>
                                                <ListItem>Verifyed Profile Count</ListItem>
                                            </List>

                                        </GridColumn>
                                    </GridRow>
                                </Grid> */}

                                {/* {ele?.shadowMode === 1 ? <Label className="ui tag label" title="Shadow Mode" color="purple">Shadow Mode</Label> :
                                    <>
                                        {ele?.mediaStatus === 2 ?
                                            <Label className="ui tag label" title="Rejected Images" color="brown"  >Rejected Images </Label> :
                                            <Label className="ui tag label" color={ele?.status === 2 ? "red" : ele?.status === 0 ? "orange" : "green"}>
                                                {ele.status == 3 ? "deleted" : ele.status == 2 ? "Inactive" : ele.status == 1 ? "Active" : "New"}
                                            </Label>}
                                    </>
                                } */}

                                {/* {ele.initiatedImage !== 0 ? <Label title="New Images" circular color="orange"  >{ele.initiatedImage} </Label> : null} */}

                            </CardContent>
                        </Card>



                        // <Card key={ele._id}>
                        //     <CardContent>
                        //         <Label style={{ paddingLeft: "15px" }} className="circular-label" circular>{index + 1}</Label>
                        //         {/* <CardHeader style={{ color: "#6f6c6c", textAlign: 'center' }}>   {momentTz.utc(ele?.createdAt).tz("Asia/Kolkata").format("DD-MM-YYYY hh:mm:ss A [IST]")} </CardHeader> */}
                        //         <Grid columns={2} divided style={{ marginTop: "2px" }} >
                        //             <GridRow >
                        //                 <GridColumn className="box-center">
                        //                     <Image
                        //                         floated='right'
                        //                         // circular={true}
                        //                         size='mini'
                        //                         src={ele.qrImage}
                        //                         style={{ height: "50px", width: "50px", objectFit: "cover" }}
                        //                     />
                        //                 </GridColumn>
                        //                 <GridColumn className="box-center">
                        //                     <Image
                        //                         floated='right'
                        //                         // circular={true}
                        //                         size='mini'
                        //                         src={ele.profilePic}
                        //                         style={{ height: "50px", width: "50px", objectFit: "cover" }}
                        //                     />
                        //                 </GridColumn>
                        //             </GridRow>
                        //         </Grid>
                        //     </CardContent>
                        // </Card>
                    ))}</>
                    : <NoData />}
            </CardGroup>

            <Modal
                onClose={() => setState((p) => ({ ...p, filterModal: false }))}
                open={state.filterModal}
                size="mini"
            >
                <ModalHeader>Filter</ModalHeader>
                <ModalContent>
                    <Grid columns='equal'>
                        <GridRow>
                            <GridColumn>
                                Start Date
                                <Input type="date"
                                    // value={pageRequest.filterValue.startDate}
                                    onChange={(e, data) => { pageRequest.filterValue.startDate = e.target.value }}
                                />
                            </GridColumn>
                            <GridColumn>
                                End Date
                                <Input type="date"
                                    // value={pageRequest?.filterValue?.endDate}
                                    onChange={(e, data) => { pageRequest.filterValue.endDate = e.target.value }}
                                />
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </ModalContent>
                <ModalActions>
                    <Button negative onClick={() => setState((p) => ({ ...p, filterModal: false }))}>Cancel</Button>
                    <Button positive onClick={() => applyFilter()}>Apply</Button>
                </ModalActions>
            </Modal>
        </>
    );
};
export default Insights;