

export const GETSTUREREJECT = [
    { key: 1, text: 'Gesture Mismatch', value: 1 },
    { key: 2, text: 'Unclear Photo', value: 2 },
    { key: 3, text: 'Low-Quality or Blurred Image', value: 3 },
    { key: 4, text: 'Invalid Submission', value: 4 },
    { key: 5, text: 'Other', value: 5 }
]
export const ERROR = "Something went wrong, Please try again"

export const VERIFICATIONREJECTION = [
    { key: 1, text: 'Unclear Photo', value: 1 },
    { key: 2, text: 'Cropped or Incomplete ID', value: 2 },
    { key: 3, text: 'Details Do Not Match', value: 3 },
    { key: 4, text: 'Invalid Document', value: 4 },
    { key: 5, text: 'Incorrect ID Type Selected', value: 5 },
    { key: 6, text: 'Other', value: 6 }
]

export const DATEFILTER = [
    { key: 1, text: 'Created', value: "createdAt" },
    { key: 2, text: 'Updated', value: "updatedAt" },
]


export const USERIMAGEREJECT = [
    { key: 1, text: "Unclear Image", value: 1, },
    { key: 2, text: "Inappropriate Content", value: 2 },
    { key: 3, text: "Offensive Gestures", value: 3 },
    { key: 4, text: "Underaged Content Detected", value: 4 },
    { key: 5, text: "Altered or Filtered", value: 5 },
    { key: 6, text: "Invalid Photo Uploaded", value: 6 },
    { key: 7, text: "Personal Information Found", value: 7 },
    { key: 8, text: "Duplicate Photo Detected", value: 8 },
    { key: 11, text: "Group photo detected", value: 11 },
    { key: 9, text: "Other", value: 9 },

];

export const GESTUREIMAGES = [
    {
        type: 1,
        image: "https://d1xo93lth1py89.cloudfront.net/userMedia/2024/1727939793060914.png"
    }, {
        type: 2,
        image: "https://d1xo93lth1py89.cloudfront.net/userMedia/2024/17279398438335418.png"
    }, {
        type: 3,
        image: "https://d1xo93lth1py89.cloudfront.net/userMedia/2024/17279398128265760.png"
    }
]


export const LISTOFTOPIC = [
    // { key: 8, text: "Staging Users", value: "pickZonStagings" },
    { key: 1, text: "All Users", value: "pickzonNotification", },
    { key: 2, text: "Premium Users", value: "premium" },
    { key: 3, text: "Premium Plus Users", value: "premiumplus" },
    { key: 4, text: "Approved Users", value: "approvedUsers" },
    { key: 5, text: "Rejected Users", value: "rejectedUsers" },
    { key: 6, text: "Document Verified Users", value: "verificationRequest" },
    { key: 7, text: "Document Rejected Users", value: "NonVerificationRequest" },
    { key: 8, text: "Gesture Verified Users", value: "userVerifyGesture" },
    { key: 9, text: "Gesture Rejected Users", value: "NonUserVerifyGesture" },
    { key: 10, text: "Premium Trial Users", value: "freeTrialPremium" },
    { key: 11, text: "Non Premium Users", value: "NonPremium" },







]

export const LISTOFNOTIFICATION = [
    { key: 1, text: "Your Holiday Match Awaits!🎄", value: "Find someone to share the magic of Christmas with on Pickzon", },
    { key: 2, text: "Christmas Connection Alert!🎅", value: "Swipe and meet your holiday match today! Pickzon is buzzing with festive spirit" },
    { key: 3, text: "🎄 Make the Holidays Magical!", value: "Swipe now and connect with someone who makes this season brighter on Pickzon" },
]

export const ALLFIELDSAREMANDATORY = "All fields are mandatory"
export const SELECTTOPIC = "Please select topic"
export const ETERTITLE = "Please enter title"
export const ENTERDESCRIPTION = "Please description"
export const SUCCESSFULLYSENDNOTIFY = "Notification send successfully"




export const STATUS = {
    New: "#fbbd08",
    Inactive: "red",
    Active: "green"
}

export const GENDER = {
    Man: "#fbbd08",
    Woman: "green",
    Nonbinary: "orange"
}


export const DROPPAGIOPTION = [
    {
        key: 1,
        text: 25,
        value: 25,
    },
    {
        key: 2,
        text: 50,
        value: 50,
    },
    {
        key: 3,
        text: 75,
        value: 75,
    },
    {
        key: 4,
        text: 100,
        value: 100,
    }
]


export const REPORTREASION = [
    { key: "reason", value: 1, text: "I'm not interested in this person" },
    { key: "reason", value: 2, text: "Profile is fake, spam or scammer" },
    { key: "reason", value: 3, text: "Inappropriate content" },
    { key: "reason", value: 4, text: "Off-Pickzon behaviour" },
    { key: "reason", value: 5, text: "Underage or minor" },
    { key: "reason", value: 6, text: "Someone is in danger" }

]

export const GENDEROPTION = [
    { key: "gender", text: "Man", value: 1, label: { color: "yellow", empty: true, circular: true } },
    { key: "gender", text: "Woman", value: 2, label: { color: "green", empty: true, circular: true } },
    { key: "gender", text: "Nonbinary", value: 3, label: { color: "orange", empty: true, circular: true } }
];

export const SEARCHOPTIONS = [
    { key: 'Reported', text: 'Reported', value: 'reported' },
    { key: 'Reported By', text: 'Reported By', value: 'reportedby' },
]


export const PREMIUM = [
    { key: "premium", text: "Normal Users", value: 1, label: { color: "yellow", empty: true, circular: true } },
    { key: "premium", text: "Premium Users", value: 2, label: { color: "orange", empty: true, circular: true } },
    { key: "premium", text: "Premium Plus Users", value: 3, label: { color: "green", empty: true, circular: true } }
];


export const PREMIUMICON = {
    2: "https://d1xo93lth1py89.cloudfront.net/userMedia/thumb/icons//17386495597121486.png",
    3: "https://d1xo93lth1py89.cloudfront.net/userMedia/thumb/icons//17386495597177946.png"
}

export const USERSTATUS = {
    0: "New",
    1: "Active",
    2: "Inactive"
}


