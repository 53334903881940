import { lazy } from "react";
import OrderHistory from "../../adminPanel/rightSIdeDrawer/order/orderHistory.jsx";
import PushNotification from "../../adminPanel/rightSIdeDrawer/pushNotification/pushNotification.jsx";
import AgentUsers from "../../adminPanel/rightSIdeDrawer/Users/AgentUsers.jsx";
import Insights from "../../adminPanel/rightSIdeDrawer/Users/Insights.jsx";

//:::dashboard:::
const Dashboard = lazy(() => import("../../adminPanel/rightSIdeDrawer/Dashboard/Dashboard"));

//:::admin:::
const AdminLogs = lazy(() => import("../../adminPanel/rightSIdeDrawer/Admin/AdminLogs/AdminLogs.jsx"));
const AddSubAdmin = lazy(() => import("../../adminPanel/rightSIdeDrawer/Admin/AddSubAdmin.jsx"));
// const AdminAccess = lazy(() => import("../../adminPanel/rightSIdeDrawer/Admin/AdminAccess.jsx"));
// const ViewAdminLogs = lazy(() => import("../../adminPanel/rightSIdeDrawer/Admin/ViewAdminLogs.jsx"));
const AdminListPackage = lazy(() => import("../../adminPanel/rightSIdeDrawer/Admin/AdminLIstPackage.jsx"));

//:::user:::
const UserList = lazy(() => import("../../adminPanel/rightSIdeDrawer/Users/UserList"))
const ModifiedUsers = lazy(() => import("../../adminPanel/rightSIdeDrawer/Users/ModifiedUsers.jsx"))
const ReferralUserList = lazy(() => import("../../adminPanel/rightSIdeDrawer/Users/ReferralUserList.jsx"))
const VerificationReq = lazy(() => import("../../adminPanel/rightSIdeDrawer/verificationRequests/VerificationRequests.jsx"))
const GestureList = lazy(() => import("../../adminPanel/rightSIdeDrawer/Gesture/userGesture.jsx"));
const ReportedUsers = lazy(() => import("../../adminPanel/rightSIdeDrawer/Users/ReportedUsers.jsx"));
const TrashUsers = lazy(() => import("../../adminPanel/rightSIdeDrawer/Users/trashUsers"))
const QrScanList = lazy(() => import("../../adminPanel/rightSIdeDrawer/Users/QrScanUsers"));

const NotFound = lazy(() => import("../../adminPanel/NotFound"));

const ViewUser = lazy(() => import("../../adminPanel/rightSIdeDrawer/Users/user/UserView.jsx"));

//:::blogs:::
const AddBlog = lazy(() => import("../../adminPanel/rightSIdeDrawer/Blogs/AddBlog"));
const Bloglist = lazy(() => import("../../adminPanel/rightSIdeDrawer/Blogs/blogList"));
const BlogCategories = lazy(() => import("../../adminPanel/rightSIdeDrawer/Blogs/BlogCategories"));

// :: Gift :: //
const GiftList = lazy(() => import("../../adminPanel/rightSIdeDrawer/Package/GiftList.jsx"));
const PackagePlans = lazy(() => import("../../adminPanel/rightSIdeDrawer/Package/PackagePlan.jsx"));


const PAGE_ROUTES = [
   {
      path: "/",
      exact: true,
      main: () => <Dashboard />
   },
   {
      path: "/adminlist",
      main: () => <AdminListPackage />
   },
   {
      path: "/add-subadmin",
      main: () => <AddSubAdmin />
   },
   {
      path: "/admin-logs",
      main: () => <AdminLogs />
   },
   // {
   //    path: "/admin-access",
   //    exact: true,
   //    main: () => <AdminAccess />
   // },
   // {
   //    path: "/view-logs/:id",
   //    main: () => <ViewAdminLogs />
   // },
   {
      path: "/add-blog",
      main: () => <AddBlog />
   },
   {
      path: "/blog-list",
      main: () => <Bloglist />
   },
   {
      path: "/blog-categories",
      main: () => <BlogCategories />
   },
   {
      path: "/dashboard",
      main: () => <Dashboard />
   },
   {
      path: "/user-list",
      main: () => <UserList />
   },
   {
      path: "/modified-users",
      main: () => <ModifiedUsers />
   },
   {
      path: "/referral-user-list",
      main: () => <ReferralUserList />
   },

   {
      path: "/agent-users",
      main: () => <AgentUsers />
   },

   {
      path: "/insights",
      main: () => <Insights />
   },


   {
      path: "/qr-scan-list",
      main: () => <QrScanList />
   },
   {
      path: "/view-user/:id",
      main: () => <ViewUser />
   },
   {
      path: "/verification-requests",
      main: () => <VerificationReq />
   },
   {
      path: "/gesture-list",
      main: () => <GestureList />
   },
   {
      path: "/reported-users",
      main: () => <ReportedUsers />
   },
   {
      path: "/trash-users",
      main: () => <TrashUsers />
   },
   {
      path: "/order-list",
      main: () => <OrderHistory />
   },
   {
      path: "/push-notification",
      main: () => <PushNotification />
   },
   {
      path: "/gift-list",
      main: () => <GiftList />
   },
   {
      path: "/package-plans",
      main: () => <PackagePlans />
   },
   {
      path: "*",
      main: () => <NotFound />
   }

];
export default PAGE_ROUTES;