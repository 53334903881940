
import React from "react";
import { CSVLink } from "react-csv";
import { Button, Icon, Loader } from "semantic-ui-react";

const CSVDownload = (props) => {

    console.log(props)
    return (
        <>
            <Button icon labelPosition='right' onClick={() => props?.generateCSV()}>
                {props?.CSVData?.length ?
                    <CSVLink data={props?.CSVData}
                        filename={"users.csv"}
                        className="btn btn-primary"
                        target="_blank">
                        Download
                    </CSVLink>
                    : <> Request CSV</>}
                <Loader
                    active={props?.csvLoader}
                    size="small" style={{ marginLeft: props?.CSVData?.length ? '54px' : '62px' }} />
                <Icon name='download' />
            </Button>
        </>
    )
}
export default CSVDownload;
