import React from "react";
import styled from "styled-components";

const OverlapImage = (props) => {
    const images = [
        "https://picsum.photos/300/300",
        "https://picsum.photos/500/500",
        "https://picsum.photos/600/600",
    ];
    const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
  `;
    const RoundedImage = styled.img`
    width: 50px; /* Adjust the size */
    height: 50px; /* Adjust the size */
    border-radius: 50%; /* Makes the image round */
    border: 2px solid white; /* Optional border */
    object-fit: cover;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
    position: relative;
    margin-left: ${({ index }) => (index === 0 ? "0px" : "-20px")}; /* Overlap control */
    cursor: pointer;
  `;

    const MoreImages = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ddd;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  margin-left: -25px;
  z-index: 0;
  cursor: pointer;
`;
    const maxVisible = 3;
    return (
        <>
            <ImageContainer >
                {(props?.images || images).slice(0, maxVisible).map((src, index) => (
                    <RoundedImage
                        onClick={() => { props?.onClick() }}
                        key={index}
                        src={src}
                        alt={`Overlapping ${index}`}
                        index={index}
                    />
                ))}
                {props?.images.length > maxVisible && (
                    <MoreImages
                        onClick={() => { props?.onClick() }}
                    >+{props?.images.length - maxVisible}</MoreImages>
                )}
            </ImageContainer>
        </>
    )
}

export default OverlapImage;