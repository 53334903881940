
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Header, Modal, ModalContent, ModalHeader, Segment } from "semantic-ui-react";
import moment from "moment";
import { UPLOAD } from "../Services";
import { notifyToast } from "./toast";
import { USERSTATUS } from "./constants";

// return the user data from the session storage
export const getUser = () => JSON.parse(sessionStorage.getItem('LoggedUser')) || null;


export const getCSRFToken = () => JSON.parse(sessionStorage.getItem('csrfToken')) || null;
export const setCSRFToken = (cToken) => sessionStorage.setItem('csrfToken', JSON.stringify(cToken));

// return the token from the session storage
export const getToken = () => JSON.parse(sessionStorage.getItem('authToken')) || null;

export const machineId = async () => {
    let machineId = await sessionStorage.getItem('machineId');
    return machineId;
}

export const generateMachineId = async () => {
    let mask = 'abcdPOIKQLefghijklTPOLMQAWmnopqrstuvwxyz0123456789'
    let machineId = '';
    for (let i = 12; i > 0; --i) machineId += mask[Math.floor(Math.random() * mask.length)];
    sessionStorage.setItem('machineId', machineId);
    return machineId;
}
// remove the token and user from the session storage
export const removeUserSession = () => {
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('LoggedUser');
    sessionStorage.removeItem('machineId');
    window.localStorage.clear()
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
    sessionStorage.setItem('authToken', JSON.stringify(token));
    sessionStorage.setItem('LoggedUser', JSON.stringify(user));

};

export const shortTitleLength = (str, len) => {
    let N = str ? str : ""
    return N.length > len ? N.substring(0, len) + "..." : N;
};
export const KFormatter = (number) => {
    let SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
    let tier = Math.log10(Math.abs(number)) / 3 | 0;
    if (tier === 0) return number;
    let suffix = SI_SYMBOL[tier];
    let scale = Math.pow(10, tier * 3);
    let scaled = number / scale;
    return scaled.toFixed(1) + suffix;
}

export const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
}

export const NoData = ({ message }) => {
    return (
        <Segment basic style={{ marginLeft: "auto", marginRight: "auto" }}>
            <Header as="h1" textAlign="center" >
                {message ? message : "No Data Found"}
            </Header>
        </Segment>
    );
};

export const EmptyImagePlace = ({ message }) => {
    return (
        <Segment basic style={{ marginLeft: "auto", marginRight: "auto" }}>
            <Header as="h1" textAlign="center" >
                {message ? message : "No Image Found"}
            </Header>
        </Segment>
    );
};

export const base64ToBlob = async (base64) => {
    try {
        let res = await fetch(base64);
        return res?.blob();
    } catch (error) {
        console.log("base64ToBlob-error", error);
    };
};

export const dateTimeFormat = (inputTime) => {
    try {
        return moment(new Date(inputTime instanceof Date ? inputTime : new Date())).format("DD-MM-YYYY, hh:mm:ss A")
    } catch (error) {
        return moment().format("DD-MM-YYYY, hh:mm:ss A")
    }
}

export const convertTimeToUTCAndIST = (inputTime) => {
    const UTC = moment.utc(new Date(inputTime)).format("DD-MM-YYYY, hh:mm:ss A");
    const IST = moment(new Date(inputTime)).format("DD-MM-YYYY, hh:mm:ss A");
    return {
        UTC: UTC === 'Invalid date' ? "-" : UTC,
        IST: IST === 'Invalid date' ? "-" : IST
    };
}

export const fileToUrlConverter = async (urlOfFiles = [], bucketKey = 'externalAssets') => {
    try {
        if (urlOfFiles.length > 10 && urlOfFiles.length === 0) {
            notifyToast("Can't upload more than 10 files", "error", "bottom-right", 2500)
            return []
        }
        const response = await UPLOAD("/upload/upload-image", urlOfFiles, bucketKey);
        if (!response.status) {
            return notifyToast(response.message, "error", "bottom-right", 2500);
        }
        return response.payload;
    } catch (error) {
        console.log("fileToUrlConverter-error", error);
    }
}

export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
            // func(...args);
        }, timeout);
    };
}

export const dateFormatter = (dateString) => {
    //:::data store in db::: => "2005-04-15"(yyyy/mm/dd)  or "04-11-2008"(dd-mm-yyyy) or "12/4/1995"(m/d/yyyy) or "2010-01-08T18:30:00.000+00:00"(yyyy-mm-ddTHH:MM:SS.sss)
    try {
        const addZero = (str) => str?.toString()?.padStart("2", 0); //:::add zero for single digit:::
        const isoString = dateString?.split('T')[0];
        const isWithSlash = isoString?.search('/') > 0;
        const isWithHyphen = isoString?.search('-') > 0;
        let getDate = [];
        let normalDate = "";
        if (isWithSlash) {
            getDate = isoString?.split('/')
        } else {
            getDate = isoString?.split('-')
        }
        if (isWithHyphen && getDate[0].length === 4) {
            normalDate = `${addZero(getDate[0])} -${addZero(getDate[1])} -${addZero(getDate[2])} `
        } else if (isWithHyphen && getDate[0].length !== 4) {
            normalDate = `${addZero(getDate[2])} -${addZero(getDate[0])} -${addZero(getDate[1])} `
        } else {
            normalDate = `${addZero(getDate[2])} -${addZero(getDate[1])} -${addZero(getDate[0])} `
        }
        return normalDate; // expected format => yyyy/mm/dd
    } catch (error) {
        console.log("dateFormatter-utils", error);
    }
}

export const isoDateToInputDate = (isoDateString) => {
    try {
        const time = new Date(isoDateString)?.toTimeString()?.split(":")?.slice(0, 2)?.join(":");
        const date = new Date(isoDateString)?.toISOString()?.split('T')[0];
        return `${date}T${time}`;
    } catch (error) {
        console.log("isoDateToInputDate-utils", error);
    }
}

export const camelCaseToCapitalCase = (camelCaseText) => {
    if (!camelCaseText) return;
    const camelCase = camelCaseText.replace(/([A-Z])/g, " $1");
    const capitalCase = `${camelCase.charAt(0).toUpperCase()}${camelCase.slice(1)} `
    return capitalCase;
}

export const utcToInputDate = (utcDateString) => {
    try {
        const formatInIST = moment(new Date(utcDateString)).format();
        const dateAndTime = formatInIST?.split("T");
        const getDate = dateAndTime[0];
        const getTime = dateAndTime[1]?.split("+")[0];
        return `${getDate}T${getTime}`;
    } catch (error) {
        console.log("utcToInputDate-utils", error);
    }
}

export const ShowHTMLData = ({ htmlData, heading = 'Editor Data' }) => {
    const [openModal, setOpenModal] = useState(false);
    return (
        <Modal
            closeIcon
            onClose={() => setOpenModal(false)}
            onOpen={() => setOpenModal(true)}
            open={openModal}
            trigger={<h4 style={{ color: "#000fff", textDecoration: "underline", cursor: 'pointer' }}>View</h4>}>
            <ModalHeader>{heading}</ModalHeader>
            <ModalContent scrolling>
                {htmlData?.length ?
                    < div
                        style={{ height: "300px", overflow: "auto", marginTop: "0.5rem" }}
                        dangerouslySetInnerHTML={{ __html: htmlData }}
                    />
                    :
                    <NoData />
                }
            </ModalContent>
        </Modal>
    )
}

export const verifiedTick = (
    statusType,
    styleVariant = { width: "1em", height: "1em", marginLeft: "0.2em", cursor: "pointer" },
    assignedOn = ""
) => {
    try {
        if (statusType === 1) {
            return (<img src=""
                alt={""}
                title={`PickZon Green V User ${assignedOn ? `\n Assigned on : ${convertTimeToUTCAndIST(assignedOn).IST}` : ""}`}
                style={styleVariant} />);
        } else if (statusType === 4) {
            return (<img src=""
                alt={""}
                title={`PickZon Gold V User ${assignedOn ? ` \n Assigned on : ${convertTimeToUTCAndIST(assignedOn).IST}` : ""}`}
                style={styleVariant} />);
        } else if (statusType === 5) {
            return (<img src=""
                alt={""}
                title={`PickZon Blue V User  ${assignedOn ? ` \n Assigned on : ${convertTimeToUTCAndIST(assignedOn).IST}` : ""}`}
                style={styleVariant} />);
        } else {
            return null;
        }
    } catch (error) {
        console.log("verifiedTick", error);
    };
};

const premiumStatus = {
    2: "Premium",
    3: "Premium Plus"
}

export const userCSV = (users) => {
    try {
        const listOfUsers = users.map((e) => ({
            Name: e?.firstName + " " + e?.lastName,
            Mobile: e?.mobile,
            Email: e?.email,
            "Premium Status": premiumStatus[e?.role],
            "Gesture Verification": e?.gestureVerify,
            "Document Verification": e?.docVerify,
            Location: e?.location,
            Profile: e?.image,
            Status: USERSTATUS[e?.status],
            DateTime: e?.createdAt
        }))
        return listOfUsers;
    } catch (error) {
        console.log("userCSV=====>>", error)
    }
}

export const deleteUserCSV = (users) => {
    try {
        const listOfUsers = users.map((e) => ({
            Name: e?.firstName + " " + e?.lastName,
            Mobile: e?.mobile,
            Email: e?.email,
            Location: e?.address ? `${e?.address?.city}, ${e?.address?.state}, ${e?.address?.country}` : "",
            Profile: e?.image,
            Status: USERSTATUS[e?.status],
            Reason: e?.reason,
            DateTime: e?.createdAt
        }))
        return listOfUsers;
    } catch (error) {
        console.log("deleteUserCSV=====>>", error)
    }
}

export const reportedUserCSV = (users) => {
    try {
        let listOfUser = []
        for (let user of users) {
            const reportedBy = user?.reportedBy
            for (let reported of reportedBy) {
                const userList = {
                    "Reported Name": user?.userInfo?.name,
                    "Reported Mobile": user?.userInfo?.mobile,
                    "Reported Email": user?.userInfo?.email,
                    "Reported Gender": user?.userInfo?.gender,
                    "Reported Status": USERSTATUS[user?.userInfo?.status],
                    "Reported By Name": reported?.name,
                    "Reported By Mobile": reported?.mobile,
                    "Reported By Email": reported?.email,
                    "Reported By Gender": reported?.gender,
                    "Reason": reported?.reason,
                    "Reported By Status": USERSTATUS[reported?.status],
                    DateTime: moment(reported?.createdAt).format("DD-MM-YYYY, hh:mm:ss A"),
                }
                listOfUser.push(userList)
            }
        }
        return listOfUser;
    } catch (error) {
        console.log("reportedUserCSV=====>>", error)
    }
}

const orderStatus = {
    0: "Initialize",
    1: "Success",
    2: "Pending",
    4: "Failed"
}


export const OrderHistoryCSV = (users) => {
    try {
        const listOfOrder = []
        for (let user of users) {
            const userObj = {
                "Name": `${user?.userInfo?.firstName} ${user?.userInfo?.lastName}`,
                "Mobile No": user?.userInfo?.mobile,
                "Email Id": user?.userInfo?.email,
                "Order Id": user?.orderId,
                "Transaction Id": user?.transactionId,
                "Currency": user?.currency,
                "Amount": user?.amount,
                "Purchased": user?.title,
                "Status": orderStatus[user?.status],
                "Date Time": moment(user?.createdAt).format("DD-MM-YYYY, hh:mm:ss A"),
            }
            listOfOrder.push(userObj)
        }
        return listOfOrder;
    } catch (error) {
        console.log("OrderHistoryCSV=====>>", error)
    }
}


export const insightsCSV = (users) => {
    try {
        const listOfOrder = []
        for (let user of users) {
            const userObj = {
                "QR Code": user?.referralCode, Name: "", Mobile: "", Email: "", Age: "", Address: "", Status: "",
                Gender: "", "Scan Date": "", "Download Date": "", "Sign-Up Date": ""
            }
            // userObj["verifyUserCount"] = user?.verifyUserCount;
            // userObj["signUpCompleteUserCount"] = user?.signUpCompleteUserCount
            // userObj["appDownloadCount"] = user?.appDownloadCount
            // userObj["scanHitCount"] = user?.scanHitCount

            const signUpUsers = user?.signUpCompleteUser;
            for (let sign of signUpUsers) {
                const { name, email, mobile, age, address, status, gender, createdAt } = sign?.signUpUser;
                userObj["Name"] = name;
                userObj["Mobile"] = mobile;
                userObj["Email"] = email;
                userObj["Address"] = address;
                userObj["Status"] = USERSTATUS[status];
                userObj["Gender"] = gender;
                userObj["Age"] = moment(age).format("DD-MM-YYYY");
                userObj["Sign-Up Date"] = createdAt
                listOfOrder.push(userObj)
            }
            for (let download of (user?.appDownload)) {
                userObj["Download Date"] = download?.createdAt;
                listOfOrder.push(userObj)
            }
            for (let scanHit of (user?.scanHit)) {
                userObj["Scan Date"] = scanHit?.createdAt;
                listOfOrder.push(userObj)
            }
        }
        return listOfOrder;
    } catch (error) {
        console.log("insightsCSV=====>>", error)
    }
}


// const userObj = {
//     "QR Code / Code": user?.referralCode,
//     "Name": name,
//     "Mobile No": mobile,
//     "Email Id": email,
//     Age: moment(age).format("DD-MM-YYYY"),
//     Address: address,
//     Status: USERSTATUS[status],
//     Gender: gender,
// }