/* eslint-disable no-new-object */
import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Button, Image, Pagination, Grid, Card, Dropdown, Input, Icon, Modal, Label, Accordion, Menu, CardGroup,
  CardContent, CardHeader, CardMeta, ModalDescription, ModalHeader, ModalContent, ModalActions,
  TableHeader, TableRow, Table, TableHeaderCell, TableBody, TableCell, GridColumn, GridRow,
  LabelDetail
} from "semantic-ui-react";
import { POST } from "../../../Services";
import { getUser, shortTitleLength } from "../../../utils/common"
import { notifyToast } from "../../../utils/toast";
import { stopLoader } from "../../../utils/sweetAlert";
import "./style.css";
import OverlapImage from "../../component/ImageSlider/OverlapImage";
import { DROPPAGIOPTION, GENDEROPTION, REPORTREASION, SEARCHOPTIONS } from "../../../utils/constants";
import ModalAgentUsers from "../../component/modal/ModalAgentUsers";

let pageLimit = 25;
let filterCount = 0;
const pageRequest = {
  pageNumber: 0,
  pageLimit: pageLimit,
  filterValue: {},
  search: "",
  sort: {},
  selectReport: ""
}

const defaultFilters = {
  gender: "",
  reason: "",
  startDate: "",
  endDate: "",
}


const ReportedUsers = () => {
  const [reportedData, setReportedData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [sortingUser, setSortingUser] = useState(false);
  const loggedInUser = getUser();
  const [pagiActivePage, setPagiActivePage] = useState(1);
  const [manualSerach, setManualSearch] = useState("");
  const [filterObj, setFilterObj] = useState(defaultFilters);
  const [filterModal, setFilterModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loader, setLoader] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filterGenderLabel, setFilterGenderLabel] = useState('Gender');
  const [filterReasonLabel, setFilterReasonLabel] = useState('Reject Reason');
  const [reportedModal, setReportedModal] = useState(false)
  const [reportedUser, setReportedUser] = useState([])
  const [reportedDefalut, setReportedDefalut] = useState("reported")
  const [agentModal, setAgentModal] = useState(false)
  const [userSearch, setUserSearch] = useState("")
  const [userSearchData, setUserSearchData] = useState([])
  const [childAgentUser, setChildAgentUser] = useState()
  const [chooseAgent, setChooseAgent] = useState([]);




  useEffect(() => {
    function callEff() {
      pageRequest.pageNumber = 0;
      pageRequest.pageLimit = 27;
      pageRequest.filterValue = {};
      pageRequest.search = "";
      pageRequest.selectReport = "";
      pageRequest.sort = {};
      getUserAgents({ pageNum: 0 });
      agentUserSearch()
      setChildAgentUser()
    }
    callEff();
  }, []);



  const agentUserSearch = async () => {
    try {
      const { payload } = await POST("/v1/user/make-agent/", { search: userSearch, parentAgentId: childAgentUser?._id, type: 2 });
      setUserSearchData(payload)
    } catch (error) {
      console.log(error)
    }
  }

  const getUserAgents = async () => {
    setLoader(undefined)
    let { status, totalRecords, totalPages, message, payload } = await POST(
      "/v1/user/get-user-agents",
      {
        userId: loggedInUser._id,
        ...pageRequest
      }
    );
    stopLoader();

    if (status === 0) {
      setReportedData([]);
      setTotalRecords(0)
      setLoader(0)
      return console.log(message);
    }
    setTimeout(() => {
      setTotalPages(0);
      setLoader(status)
      setTotalPages(totalPages);
      setTotalRecords(totalRecords);
      setReportedData(payload);
    }, 12);
  };


  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const pageChange = async (item, data) => {
    let pageNum = data.activePage - 1;
    setPagiActivePage(data.activePage);
    pageRequest.pageNumber = pageNum
    getUserAgents();
  };


  const onSearch = (e) => {
    const value = e.target.value
    setManualSearch(value)
    if (value.length === 0) {
      pageRequest.search = ""
      pageRequest.selectReport = "";
      getUserAgents();
    }
  };


  const onSearchUser = (e) => {
    const value = e.target.value
    setUserSearch(value)
    agentUserSearch()
    if (value.length === 0) {
      setUserSearch();
      agentUserSearch()
    }
  };

  const searchUserOnClick = (e) => {
    if (!userSearch) return notifyToast("Please Enter User to Search !!", "error", "bottom-right", 2500);
    agentUserSearch();
    if (!userSearch) { setUserSearch(""); agentUserSearch() };
  };


  const generateQRCode = async (parentAgent, childAgent) => {
    if (parentAgent) await POST("/v1/user/generate-user-qr-image", { userId: parentAgent });
    if (childAgent) await POST("/v1/user/generate-user-qr-image", { userId: childAgent });
  }

  const makeAgentUser = async ({ parentAgent, childAgent }) => {
    const isChooseAgent = chooseAgent.includes(childAgent)
    generateQRCode(parentAgent, childAgent);
    const { status, message } = await POST("/v1/user/make-agent/",
      { parentAgentId: parentAgent, childAgentId: childAgent, type: isChooseAgent ? 0 : 1 });
    notifyToast(message, status ? "now this user become an agent" : "error")
    getUserAgents();
  }

  const searchOnClick = (e) => {
    if (!manualSerach) return notifyToast("Please enter user to search !!", "error", "bottom-right", 2500);
    pageRequest.pageNumber = 0
    pageRequest.search = manualSerach
    pageRequest.selectReport = reportedDefalut;
    getUserAgents();
    if (!manualSerach) {
      pageRequest.search = ""
      pageRequest.reportedDefalut = ""
      getUserAgents()
    }
  };


  const changeRange = (e, data) => {
    setTimeout(() => {
      setPagiActivePage(1)
      pageRequest.pageNumber = 0;
      pageRequest.pageLimit = data.value
      getUserAgents({ pageNum: 0 });
    }, 0);

  };
  const sortUsers = (type) => {
    try {
      if (type === 0) {
        pageRequest.sort = { field: "createdAt", order: 1 }
        getUserAgents();
        setSortingUser(true);
      } else {
        pageRequest.sort = { field: "createdAt", order: -1 }
        getUserAgents();
        setSortingUser(false);
      }
    } catch (er) {
      console.log(er);
    }
  };
  const selectDate = async (e, data, type) => {

    try {
      if (type === "startDate") {
        setFilterObj((pre) => ({
          ...pre,
          startDate: data.value
        }))
      } else {
        setFilterObj((pre) => ({
          ...pre,
          endDate: data.value
        }))
        if (filterObj.startDate === "" || filterObj.endDate === "") {
          filterCount = filterCount + 1;

        }
      }
      // fetchUserData({ pageNum: 0, filterDate });
    } catch (er) { console.log(er); };

  };

  const handleFilterList = (item) => {
    try {
      if (item.key === 'gender') {
        setFilterGenderLabel(item.text);
        if (filterObj.gender === "" || filterObj.gender === "") {
          filterCount = filterCount + 1;
        }
        setFilterObj((pre) => ({ ...pre, gender: item.value }))
      } else if (item.key === 'reason') {
        setFilterReasonLabel(item.text);
        if (filterObj.reason === "" || filterObj.reason === "") {
          filterCount = filterCount + 1;
        }
        setFilterObj((pre) => ({ ...pre, reason: item.value }))
      }
    } catch (er) {
      console.log(er);
    }
  };
  const applyFilters = (e) => {
    pageRequest.filterValue = filterObj
    getUserAgents();
    setFilterModal(false)
  };


  const clearFilters = () => {
    pageRequest.filterValue = {
      startDate: '',
      endDate: '',
      gender: '',
      reason: ''
    }
    setFilterObj((pre) => ({
      ...pre,
      startDate: '',
      endDate: '',
      gender: '',
      reason: ''
    }))
    filterCount = 0
    setFilterGenderLabel('Gender');
    setFilterReasonLabel('Reject Reason');
    getUserAgents();
    setFilterModal(false)
  };

  const reportedByHandle = (e) => {
    setReportedUser(e?.childParentsUser)
    setReportedModal(true)
  }


  const makeChildAgentUser = (e) => {
    agentUserSearch()
    setChildAgentUser(e)
    setAgentModal(true)
  }

  const makeParentAgent = () => {
    agentUserSearch()
    setAgentModal(true);
    setChildAgentUser()
  }

  const removeAgent = async (obj) => {
    const { status, message } = await POST("/v1/user/make-agent/",
      { parentAgentId: obj?.parentAgentId, childAgentId: obj?._id, type: 0 });
    notifyToast(message, status ? "now this user become an agent" : "error")
    getUserAgents()
    setReportedUser((reportedUser || []).filter((e) => e?._id !== obj?._id))
    if (reportedUser.length === 1) setReportedModal(false)
  }

  return (
    <>
      <section className="fixed-position" style={{ minHeight: "90px" }}>
        <div className="fixed-position-inner">
          <Grid stackable>
            <Grid.Row className="custom-userlist">
              <Grid.Column width={3} className="custom-userlist-1">

                {/* <Button.Group>
                  <Button icon labelPosition='left' onClick={() => setFilterModal(true)}>
                    <Icon name='filter' />
                    Filters
                    {filterCount === 0 ? null : <Label color="teal" size="mini" circular floating horizontal style={{ left: "120%", borderRadius: "15px" }}>
                      {filterCount}
                    </Label>}
                  </Button>
                  {filterCount === 0 ? null : <Button color="red" icon onClick={filterCount > 0 ? () => clearFilters() : null}>
                    <Icon name="close" />
                  </Button>}
                </Button.Group> */}
                <Button color='twitter' onClick={() => { makeParentAgent() }}>
                  <Icon name='users' /> Make Parent Agent
                </Button>

              </Grid.Column>
              <Grid.Column width={2} className="custom-userlist-2">
                {sortingUser === false ? (
                  <Icon
                    name="sort amount up"
                    titile="Latest Users"
                    className="icon-font-size"
                    onClick={() => sortUsers(0, "user")}
                  />
                ) : (
                  <Icon
                    name="sort amount down"
                    titile="Oldest Users"
                    className="icon-font-size"
                    onClick={() => sortUsers(1, "user")}
                  />
                )}
              </Grid.Column>
              <Grid.Column width={4} className="custom-userlist-3" >
                <Input labelPosition='right' type='text' alue={manualSerach} placeholder='Search...' action onChange={onSearch}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      return searchOnClick();
                    }
                  }}
                >
                  <input />
                  <Button type='submit' onClick={searchOnClick} icon><Icon name="search" fitted /></Button>
                </Input>
              </Grid.Column>

              <Grid.Column width={4}>
                <Pagination
                  boundaryRange={1}
                  activePage={pagiActivePage}
                  siblingRange={0}
                  pointing
                  secondary
                  totalPages={totalPages}
                  onPageChange={(event, data) => pageChange(event, data)}
                />
              </Grid.Column>

              <Grid.Column width={1} style={{ paddingTop: 13 }} className="custom-userlist-5">
                <Dropdown
                  placeholder="25"
                  options={DROPPAGIOPTION}
                  onChange={(e, data) => changeRange(e, data)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </section>
      <CardGroup itemsPerRow={4}>
        {(reportedData || []).map((ele, index) => (
          <Card key={ele?._id} style={{ cursor: "pointer" }} >
            <CardContent>
              <Label style={{ paddingLeft: "15px" }} className="circular-label" circular>{index + 1}</Label>
              <Image
                floated='right'
                circular={true}
                size='mini'
                src={ele?.userInfo?.profilePic}
                style={{ height: "50px", width: "50px", objectFit: "cover" }} />

              <CardHeader >{ele?.userInfo?.name}<strong style={{ color: "#d1d1d1" }}> | </strong> {ele?.userInfo?.gender} </CardHeader>
              <CardHeader >{ele?.userInfo?.mobile}</CardHeader>
              <CardMeta >{ele?.userInfo.email ? shortTitleLength(ele?.userInfo.email, 25) : <span style={{ color: "#b7b6b62b" }}>___________________</span>}  </CardMeta>
              <CardMeta style={{ color: "orange" }} ><strong>{shortTitleLength(ele?.userInfo.reason, 100)}</strong></CardMeta>
              <CardMeta> <strong> {moment(ele?.userInfo?.createdAt).format("YYYY-MM-DD HH:mm:ss")}</strong> </CardMeta>
              {/* <Divider /> */}

              <Grid divided>
                <GridRow>
                  <GridColumn width={7}>
                    <OverlapImage floated="right" images={(ele?.childParentsUser || []).map((e) => e?.profilePic)} onClick={() => reportedByHandle(ele)} />
                  </GridColumn>

                  <GridColumn width={2} style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                    <Label circular color="blue">
                      {(ele?.agentCount - 1)}
                    </Label>
                  </GridColumn>
                  <GridColumn width={7}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Label color="green"
                      onClick={() => { makeChildAgentUser(ele) }}
                    >  Make Child Agent</Label>
                  </GridColumn>

                </GridRow>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </CardGroup>

      < ModalAgentUsers
        onClose={(e) => { setAgentModal(e); setUserSearch(""); }}
        onOpen={agentModal}
        value={userSearch}
        onSearch={(e) => onSearchUser(e)}
        searchOnClick={() => { searchUserOnClick() }}
        agentData={userSearchData}
        makeAgentUser={(e) => { makeAgentUser(e) }}
        childAgentUser={childAgentUser}
        setChooseAgent={(e) => { setChooseAgent(e) }}
        chooseAgent={chooseAgent}
      />

      <Modal
        open={reportedModal}
        onClose={() => setReportedModal(false)}
        onOpen={() => setReportedModal(true)}
      >
        <ModalHeader style={{ background: "rgb(50 165 251 / 88%)", color: "white" }} >Agent Users</ModalHeader>
        <ModalContent image scrolling>
          <ModalDescription>
            <Table celled >
              <TableHeader>
                <TableRow textAlign="center">
                  <TableHeaderCell singleLine>Action</TableHeaderCell>
                  <TableHeaderCell singleLine>Name</TableHeaderCell>
                  <TableHeaderCell singleLine>Mobile No.</TableHeaderCell>
                  <TableHeaderCell>Email Id</TableHeaderCell>
                  <TableHeaderCell>CreatedAt</TableHeaderCell>
                  <TableHeaderCell>Profile Image</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {(reportedUser || []).map((e, i) => (
                  <TableRow >
                    <TableCell textAlign={i === 0 ? "left" : "center"} >{i === 0 && <Label color="green" ribbon>Parent</Label>}
                      <Icon name='remove circle' size="big" color="red" onClick={() => { removeAgent(e) }} />  </TableCell>
                    <TableCell textAlign="center" singleLine >{e?.name}  </TableCell>
                    <TableCell textAlign="center" singleLine>{e?.mobile} </TableCell>
                    <TableCell textAlign="center" singleLine>{e?.email}</TableCell>
                    <TableCell textAlign="center" >{moment(e?.createdAt).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                    <TableCell textAlign="center">
                      <Image
                        centered
                        circular={true}
                        size='mini'
                        src={e?.profilePic}
                        style={{ height: "50px", width: "50px", objectFit: "cover" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ModalDescription>
        </ModalContent >
        <ModalActions>
          <Button onClick={() => setReportedModal(false)}>Cancel</Button>
        </ModalActions>
      </Modal >
    </>
  );
};
export default ReportedUsers;
